import React from "react";

function StudentType({ newStudent, setNewStudent }) {
  return (
    <>
      <div class="student-type-page">
        <section class="form-sec grey-back">
          <div class="form-inner container">
            <form>
              <div class="font-30 text-center pb-4">Student Type</div>
              <div class="combo-btn">
                <span class="sap-red-btn">
                <a target="_blank" href="https://student.connectedschool.com.au/loginFreeAssesment">
                     new student
                  </a>
                </span>
                <span class="sap-red-btn">
                <a target="_blank" href="https://student.connectedschool.com.au/login">
                Returning Student
                  </a>
                </span>
              </div>
            </form>
          </div>
        </section>
      </div>
    </>
  );
}

export default StudentType;
