import "./App.css";
import  { useEffect } from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import {
  AboutUs,
  Blogs,
  BlogDetail,
  ContactUs,
  CourseDetails,
  EnrollPage,
  ErrorPage,
  Home,
  OurCourses,
  OurHistory,
  OurBranches,
  Aboutus,
  Privacy,
  // ContactUs
} from "../src/pages/Index";
import AOS from "aos";
import "aos/dist/aos.css";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "team",
        element: (
          <div>
            Hello team! <Outlet />
          </div>
        ),
        children: [
          {
            path: "",
            element: <div>Hel1111111111111111m!</div>,
          },
        ],
      },
    ],
  },
  // {
  //   path: "about-us",
  //   element: <AboutUs />,
  //   errorElement: <ErrorPage />,
  // },
  {
    path: "our-courses",
    element: <OurCourses />,
    errorElement: <ErrorPage />,
  },
  {
    path: "course-detail",
    element: <CourseDetails />,
    errorElement: <ErrorPage />,
  },
  {
    path: "our-history",
    element: <OurHistory />,
    errorElement: <ErrorPage />,
  },
  {
    path: "blogs",
    element: <Blogs />,
    errorElement: <ErrorPage />,
  },
  {
    path: "blog-detail",
    element: <BlogDetail />,
    errorElement: <ErrorPage />,
  },
  {
    path: "our-branches",
    element: <OurBranches />,
    errorElement: <ErrorPage />,
  },
  {
    path: "contact-us",
    element: <ContactUs />,
    errorElement: <ErrorPage />,
  },
  {
    path: "about-us",
    element: <Aboutus />,
    errorElement: <ErrorPage />,
  },
  {
    path: "privacy-policy",
    element: <Privacy />,
    errorElement: <ErrorPage />,
  },
  // {
  //   path: "contact-us",
  //   element: <ContactUs />,
  //   errorElement: <ErrorPage />,
  // },
  {
    path: "enroll",
    element: <EnrollPage />,
    errorElement: <ErrorPage />,
  },
]);;
function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
