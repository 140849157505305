import React from "react";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { HTTPURL } from "../../src/constants/Matcher";
import { events_edit } from "./Dashboard";
const EventDetail = ({ open, setOpen, id }) => {
  const [evntDetail, setEvntDetail] = useState([]);
  console.log("evntDetail", evntDetail);
  useEffect(() => {
    async function get_data() {
      const data = await events_edit(id);
      setEvntDetail(data?.data[0]);
    }
    get_data();
  }, []);
  return (
    <div>
  <Modal
    show={open}
    onHide={() => setOpen(false)}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className="event-popup"
  >
    <Modal.Header closeButton>
      <Modal.Title className="modal-title-centered">
        {evntDetail?.title} ({evntDetail?.event_type})
      </Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <div className="modal-body view_modal_cont">

      <h4>Venue Name</h4>
        <p>
          {evntDetail?.venue_name}<br />
          {/* (Offline) {evntDetail?.address} */}
        </p>

        <h4>Description</h4>
        <p>{evntDetail?.description}</p>

        <h4>Event Date and Time</h4>
        <p>
          <b>Date:</b> {evntDetail?.date}<br />
          <b>Start Time:</b> {evntDetail?.break_start}<br />
          <b>End Time:</b> {evntDetail?.break_end}
        </p>

      </div>
    </Modal.Body>
  </Modal>
</div>

  );
};

export default EventDetail;