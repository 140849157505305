const PolicyBanner = ({title}) => {
    return (
      <section class="banner_sec">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="banner_text">
                <h1>{title}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
  export default PolicyBanner;
  